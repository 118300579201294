<!-- 盘存记录-查看 -->

<template>
  <div class="recordsView">
    <back />
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="codeValue" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="productNameValue" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-select v-model="typeValue" placeholder="选择盘盈/盘亏" @change="handleSelectType" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="商品类目"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="resultType" label="盘盈/盘亏">
            <template slot-scope="scope">
              <span>{{ scope.row.resultType == 1 ? '盘盈' : (scope.row.resultType == 2 ? '盘亏' : '盘平') }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="oldCount" label="盘存前库存"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="newCount" label="盘存后库存"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="changeCount" label="变更数"></el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      checkNum: null,
      options: [{
        value: '1',
        label: '盘盈'
      }, {
        value: '2',
        label: '盘亏'
      },],
      typeValue: '', // 选择类型
      codeValue: '',
      productNameValue: '', // 搜索商品名称
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.checkNum = this.$route.query.checkNum || null;
    this.getInventoryCheckDetailsList();
  },
  methods: {
    // 获取列表
    getInventoryCheckDetailsList() {
      this.loading = true;
      let params = {
        checkNum: this.checkNum,
        productName: this.productNameValue || null,
        resultType: this.typeValue || null,
        barCode: this.codeValue || null,
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$axios.get(this.$api.getInventoryCheckDetailsList, { params })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getInventoryCheckDetailsList();
    },
    // 选择类型
    handleSelectType(value) {
      this.clearData();
      this.typeValue = value;
      this.getInventoryCheckDetailsList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getInventoryCheckDetailsList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getInventoryCheckDetailsList();
    },
  }

}



</script>

<style scoped lang="scss">
.recordsView {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 280px;
      margin-left: 20px;
    }

    .el-select {
      margin-left: 20px;
    }
  }
}
</style>
